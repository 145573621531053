export const roleData =
{
    superadmin: 'superadmin',
    admin: 'admin',
    employee:'employee',
    client:'client',
    support:'support',
}

export const jobTypeData =
{
    part_time: 'part_time',
    full_time: 'full_time',
    support:'support',
}
