
export const images = {
  defaultAvatar: require("assets/images/default-avatar.png"),
  signatureImage: require("assets/images/signature-image.png"),
  preview: require("assets/images/preview.png"),
  logo: require("assets/images/logo.png"),
  document: require("assets/images/document.png"),
  noHurt: require("assets/images/no-hurt.png"),
  hurtLittleBit: require("assets/images/hurts-little-bit.png"),
  hurtLittleMore: require("assets/images/hurt-little-more.png"),
  hurtEvenMore: require("assets/images/hurt-even-more.png"),
  hurtWholeLot: require("assets/images/hurt-whole-lot.png"),
  hurtWorst: require("assets/images/hurt-worst.png"),
};
