import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

// material
import { Grid } from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { getUpdateImageFormat, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { roleList } from 'utils/constants';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const navigate = useNavigate();
    const [fileImage, setFileImage] = useState({
        logo: '',
    })
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm<any>({
        mode: 'all',
        defaultValues: {
            general_information: {
                is_active: true
            }
        }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState<any>(null);

    const [fileData, setFileData] = useState({
        objectUrl: "",
        previewImage: "",
    });


    function onSubmit(data: any) {
        setLoading(true);
        data.logo = data?.logo?.[0];
        data.roles = data?.roles?.map((item: any) => item.value);
        data.is_active = data?.is_active ? 1 : 0;
        if (formType === 'update') {
            data.id = +defaultData.id;
            data.uuid = uuid;
        }
        const apiFunc = formType === 'create' ? 'createAccount' : 'updateAccount';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'user' }) }));
                navigate(-1);
            })
            .catch((err: any) => {
                toastMessage(err.message, 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getAccountByUuid({ uuid })
                .then((res: any) => {
                    const response = res.result;
                    setDefaultData(response)
                    setFileData(getUpdateImageFormat(response.logo_path))
                })
                .catch((err: any) => {
                    toastMessage(err.message, 'error');
                }).finally(() => setLoading(false))
        }

    }, [formType === 'update', uuid]);

    useEffect(() => {
        if (defaultData) {
            reset({
                general_information: {
                    name: defaultData.name,
                    short_description: defaultData.short_description,
                    phone: defaultData.phone,
                    contact: defaultData.contact,
                    email: defaultData.email,
                    is_active: defaultData.is_active,
                    description: defaultData.description,
                },
                address: {
                    address_line1: defaultData.address_line1,
                    address_line2: defaultData.address_line2,
                    zip: defaultData.zip,
                },
            });
            setFileImage({ logo: defaultData.logo_path })
        }
    }, [defaultData])

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "company" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "general-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "company-name" })}
                            name="general_information.name"
                            control={control}
                            error={errors?.general_information?.name}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>

                    {/* <Grid item lg={8} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "slogan" })}
                            name="general_information.short_description"
                            control={control}
                            error={errors?.general_information?.slogan}
                        />
                    </Grid> */}

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone-number" })}
                            name="general_information.phone"
                            control={control}
                            error={errors?.general_information?.phone}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "contact" })}
                            name="general_information.contact"
                            control={control}
                            error={errors?.general_information?.contact}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="general_information.email"
                            control={control}
                            error={errors?.general_information?.email}
                            rules={validationRule.textbox({ type: "email", required: true })}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "company-detail" })}
                            name="general_information.description"
                            control={control}
                            minRows={6}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomCheckBox
                            name="general_information.is_active"
                            label={formatMessage({ id: "is-active" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>
            <SectionLayout title={formatMessage({ id: "address" })}>
                <Grid container spacing={2}>
                    {/* <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={provinceOption ?? []}
                            label={formatMessage({ id: "province" })}
                            name="address.province_id"
                            control={control}
                            onChangeValue={(data) => setDistrictOption(data.districts)}
                            error={errors?.address?.province_id}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={districtOption ?? []}
                            label={formatMessage({ id: "district" })}
                            name="address.district_id"
                            control={control}
                            onChangeValue={(data) => setMunicipaltitesOption(data.municipalities)}
                            error={errors?.address?.district_id}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={municipalitiesOption ?? []}
                            label={formatMessage({ id: "municipality" })}
                            name="address.municipality_id"
                            control={control}
                            error={errors?.address?.municipality_id}
                            rules={{ required: true }}
                        />
                    </Grid> */}
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address-line1" })}
                            name="address.address_line1"
                            control={control}
                            error={errors?.address?.address_line1}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address-line2" })}
                            name="address.address_line2"
                            control={control}
                            error={errors?.address?.address_line2}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "zip" })}
                            name="address.zip"
                            control={control}
                            error={errors?.address?.zip}
                            rules={validationRule.textbox({ type: "number" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "country" })}
                            name="address.country"
                            control={control}
                            error={errors?.address?.country}
                            defaultValue="Nepal"
                        />
                    </Grid>
                </Grid>
            </SectionLayout>
            {formType === 'create' &&
                <SectionLayout title={formatMessage({ id: "credential" })}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "login-email" })}
                                name="credential.email"
                                control={control}
                                error={errors?.credential?.email}
                                rules={validationRule.textbox({ required: true, type: "email" })}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "password" })}
                                name="credential.password"
                                control={control}
                                error={errors?.credential?.password}
                                type="password"
                                rules={{
                                    ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                    validate: (value: any) => (watch('confirmation_passwordasda') ? (watch('confirmation_passwordasda') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                }}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                            <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                                <InfoIcon color="primary" />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "confirm-password" })}
                                name="credential.confirmation_password"
                                control={control}
                                error={errors?.credential?.confirmation_passwordasda}
                                type="password"
                                rules={{
                                    ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                    validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                }}
                                autoComplete="off"
                            />
                        </Grid>
                        {formType === 'create' &&
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomSelect
                                options={roleList}
                                label={formatMessage({ id: "role" })}
                                name="roles"
                                control={control}
                                error={errors.roles}
                                rules={{ required: true }}
                                multiple
                            />
                        </Grid>
                    }
                    </Grid>
                </SectionLayout>
            }

            <SectionLayout title={formatMessage({ id: "files" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx"
                            label={formatMessage({ id: "choose-logo" })}
                            error={errors?.logo}
                            {...register('logo', {
                                validate: (value: any) => {
                                    const { type, size } = value?.["0"] ?? {};
                                    const isValidSize = +size / 1024 / 1024 >= 2;
                                    if (type && !(type === "image/png" || type === "image/jpg" || type === "image/jpeg" || type === "application/pdf" || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/pdf' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                                        return ".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx supported only";
                                    } else if (isValidSize) {
                                        return "Maximum size 2 MB";
                                    } else {
                                        return true;
                                    }
                                }
                            })}
                            defaultFileUrl={fileImage.logo}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`logo`, null);
                                clearErrors(`logo`);
                            }}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

        </FormLayout>
    )
}

export default Form;