// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Chip,
  Typography,
  Menu,
} from "@mui/material";
// components
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import MenuIcon from "@mui/icons-material/Menu";
import { useDate } from "hooks";
import { RootReducerState } from "utils/types";
import { useSelector } from "react-redux";
import useResponsive from "hooks/useResponsive";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 65;
const APPBAR_DESKTOP = 65;


const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.action.hover}`,
  },
}));

export default function DashboardNavbar({ onOpenSidebar, handleSideBar, toogleSidebar }: any) {
  const DRAWER_WIDTH = toogleSidebar ? 230 : 0;
  const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
  }));

  const userData = useSelector(({ auth }: RootReducerState) => auth.data);
  const { date, time, wish } = useDate();
  const isDesktop = useResponsive("up", "lg");

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>


        {/* <Searchbar /> */}
        {isDesktop && (<>
          <IconButton
            onClick={handleSideBar}
            sx={{ mr: 1, color: "text.primary", display: { lg: "block" } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography color="gray" variant="subtitle2">
            {wish + userData?.account_user?.full_name}
          </Typography>
        </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {isDesktop && (
            <Chip
              label={date + " " + time}
              style={{ backgroundColor: "#0b0426", color: "#fff" }}
            />
          )}
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
