
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from "react-intl";
import CustomTable from 'components/table';
import {  DeleteButton, TableButtonGroup } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import {  employeeClientColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useCheckRole, useToastMessage } from 'hooks';
import View from './View';
import AssignedForm from './Form';

const Employee: React.FC = () => {
    const {isAdmin , isSupport} = useCheckRole();
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();
    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.employeeClient].data,
            loading: masterPaginationReducer[masterPaginationServices.employeeClient].loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.employeeClient, params));
    }

    const columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return (
                    <TableButtonGroup>
                        {/* <TableViewButton
                            tooltipLabel="View"
                            onClick={() => handleViewOption(rows[tableMeta.rowIndex])}
                        /> */}
                        <DeleteButton
                            tooltipLabel="Delete"
                            onDelete={() => onDeleteData(rows[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    const fullNameColumn =   {
        name: "employee",
        label: formatMessage({ id: "employee" }),
        primaryColumnName:'employees.full_name_search'
      }

    function handleViewOption(rowData: any) {
        setViewModal(true);
        setSelectedData(rowData)
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteEmployeeClient({ id: data.id })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'employee' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout title={formatMessage({ id: "assigned" })} hideToolbar>
            {isAdmin && <AssignedForm refreshData={onApiCall}/>}
            <CustomTable
                columns={isAdmin ? [fullNameColumn, ...employeeClientColumns(formatMessage), columnAction]: employeeClientColumns(formatMessage)}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
            {viewModal &&
                <View
                    open={viewModal}
                    onClose={() => setViewModal(false)}
                    selectedData={selectedData}
                    headerText='Employee Detail'
                />
            }
        </PageLayout>
    )
}

export default Employee;
