import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { ButtonProps } from '@mui/material/Button';
import Save from '@mui/icons-material/Save';

interface Props extends ButtonProps {
    label?: string
}

const SaveButton = React.memo((props: Props) => {
    const { label, ...rest } = props;
    return (
        <Button
            color="primary"
            variant="contained"
            startIcon={<Save color="inherit" fontSize="inherit" />}
            {...rest}
        >
            <span>{label ?? <FormattedMessage id="save" />}</span>
        </Button>
    )
});

export { SaveButton };