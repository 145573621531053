import { createTheme } from '@mui/material/styles';
import { colorList, palette } from '.';

export const tableTheme = createTheme({
    palette: palette,
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: "unset",
                }
            }
        },

        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: "60px !important",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",

                    '&>:nth-of-type(2)': {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end"
                    }
                },
            }
        },
        // MuiTableRow: {
        //     styleOverrides: {
        //         root: {
        //             '&$selected': {
        //                 backgroundColor: `${colorList.secondaryLight} !important`
        //             }
        //         }
        //     }
        // },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    color: '#39504f',
                    fontWeight: 600,
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    padding: "10px 10px",
                    // minWidth: "140px",
                    whiteSpace: "nowrap",
                },
                body: {
                    padding: "8px 10px",
                    // minWidth: "140px",
                    whiteSpace: "nowrap",
                },
            }
        },

        // outlined input
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    //     borderColor: colorList.primary,
                    //     '@media (hover: none)': {
                    //         borderColor: colorList.primary,
                    //     },
                    // },

                    '&&[class*="MuiAutocomplete-inputRoot"]': {
                        flexWrap: "nowrap !important",
                        paddingRight: '10px !important',
                    },

                    fontSize: "14px",
                    background: "#FFFFFF",
                    // height: "28px",
                },
                input: {
                    padding: "8px",
                },
                adornedEnd: {
                    paddingRight: "8px"
                },
                adornedStart: {
                    paddingLeft: "8px"
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                    lineHeight: 0.5,

                    "&$animated": {
                        fontSize: '1rem',
                        lineHeight: 1
                    }
                }
            }
        }
    }
})