
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from "react-intl";
import dayjs from 'dayjs';

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, TableButtonGroup, EditButton, ConfirmationButton } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { leaveColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useCheckRole, useToastMessage } from 'hooks';
import Form from './Form';

const Employee: React.FC = () => {
    const { isAdmin, isSupport, isFullTime } = useCheckRole();
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();
    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [viewForm, setViewForm] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const { leaveData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            leaveData: masterPaginationReducer[masterPaginationServices.leave].data,
            loading: masterPaginationReducer[masterPaginationServices.leave].loading,
        }),
        shallowEqual
    );

    const { rows, count } = leaveData;

    useEffect(() => {
        onApiCall();

    }, [tableState]);


    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.leave, params));
    }

    const columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return dayjs(rows[tableMeta.rowIndex]?.check_in).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') && (
                    <TableButtonGroup>
                        {isAdmin &&
                            <ConfirmationButton
                                label={formatMessage({ id: "approve" })}
                                title={formatMessage({ id: "approve" })}
                                subTitle={formatMessage({ id: "approve-confirmation" })}
                                onConfirm={() => onLeaveApproved(rows[tableMeta.rowIndex])}
                            />
                        }
                        <TableEditButton
                            tooltipLabel={formatMessage({ id: "check-out" })}
                            onClick={() => handleRowClick(rows[tableMeta.rowIndex])}
                        />
                        <DeleteButton
                            tooltipLabel="Delete"
                            onDelete={() => onDeleteData(rows[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    function onLeaveApproved(data: any) {
        setDeleteLoading(true);
        services.updateLeaveStatus({ id: data.id, is_approved:1 })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "approved-message" }, { title: formatMessage({ id: 'leave' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    function handleRowClick(rowData: any) {
        setViewForm(true);
        setSelectedData(rowData)
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteAttendance({ id: data.id })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'leave' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    const nameColumn = {
        label: formatMessage({ id: "name" }),
        name: "full_name",
    }

    return (
        <PageLayout
            title={formatMessage({ id: "leave" })}
            onCreate={() => setViewForm(true)}
        >
            <CustomTable
                columns={isAdmin ? [nameColumn, ...leaveColumns(formatMessage), columnAction] : [...leaveColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />

            {viewForm &&
                <Form
                    refreshData={onApiCall}
                    selectedData={selectedData}
                    open={viewForm}
                    closeModal={() => setViewForm(false)}
                    headerText={!selectedData ? 'Apply Leave' : 'Edit Leave'}
                    formType={!selectedData ? 'create' : 'update'}
                />
            }

        </PageLayout>
    )
}

export default Employee;
