import { Grid, Typography } from "@mui/material";
import { CustomModal, SimpleTable } from "components";
import useResponsive from "hooks/useResponsive";
import { employeeClientColumns } from "utils/constants/tableColumn/column";
import { useIntl } from "react-intl";
import { getMasterPaginationData } from "redux/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { masterPaginationServices } from "utils/constants";
import { RootReducerState } from "utils/types";
import { useEffect } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedData: any;
  headerText: string;
}

const View = (props: Props) => {
  const { onClose, open, selectedData, headerText } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isDesktop = useResponsive("up", "lg");
  const { userData, loading } = useSelector(
    ({ masterPaginationReducer }: RootReducerState) => ({
      userData: masterPaginationReducer[masterPaginationServices.specificEmployeeClient].data,
      loading: masterPaginationReducer[masterPaginationServices.specificEmployeeClient].loading,
    }),
    shallowEqual
  );

  const { rows, count } = userData;

  function onApiCall() {
    dispatch(getMasterPaginationData(masterPaginationServices.specificEmployeeClient, { type: 'client', client_id: selectedData.id }));
  }

  useEffect(() => {
    onApiCall()
  },[])

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={headerText}
      size={isDesktop ? "lg" : "xs"}
    >
      <SimpleTable
        columns={employeeClientColumns(formatMessage)}
        tableData={rows}
      />
    </CustomModal>
  );
};

export default View;
