import { createTheme } from '@mui/material/styles';

export const colorList = {
    primary: '#42b3a9',
    primaryLight: '#d2efec',
    primaryDark: '#00837a',
    secondary: '#283583',
    secondaryLight: '#EEF1FE',
    secondaryDark: '#001b60',
    white: "#FFF",
    lightGray: '#F8F8F8',
    error: '#ff0606',
    errorLight: '#ffcdd2',
    errorDark: '#b71c1c',
};

export const palette = {
    primary: {
        light: colorList.primaryLight,
        main: colorList.primary,
        dark: colorList.primaryDark,
        contrastText: colorList.white,
    },
    secondary: {
        light: colorList.secondaryLight,
        main: colorList.secondary,
        dark: colorList.secondaryDark,
        contrastText: colorList.white,
    },
    error: {
        light: colorList.errorLight,
        main: colorList.error,
        dark: colorList.errorDark,
        contrastText: colorList.white,
    }
};
export const getMuiTheme = createTheme({
    palette: palette,
    components: {
        // outlined input
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // border: '1px solid red !important',
                    // '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    // '&:hover': {
                    //     border: '1px solid red',
                    //     // border: "1px solid " + colorList.primary,
                    //     '@media (hover: none)': {
                    //         // border: "1px solid " + colorList.primary,
                    //     },
                    // },
                    fontSize: "14px",
                    background: "#FFFFFF"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "20px"
                }
            }
        },

        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '14px',
                }
            }
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: "14px"
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                }
                // colorPrimary: {
                //     '&:not(&checked):not($disabled):not($focused):not($error)': {
                //         color: '#1AA659',
                //     },
                // }
            }
        },

        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(26, 166, 89, 0.9)",
                    color: "#FFFFFF",
                },
                deleteIcon: {
                    color: "white"
                }
            }
        },
    }
})