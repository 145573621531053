
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, TableButtonGroup, TablePersonEditButton, TableFlagButton, TableViewButton } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { employeeColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import View from './View';
import FlagForm from './FlagForm';

const Employee: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [viewFlagForm, setViewFlagForm] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.employee].data,
            loading: masterPaginationReducer[masterPaginationServices.employee].loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.employee, params));
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return (
                    <TableButtonGroup>
                        <TableViewButton
                            tooltipLabel="View"
                            onClick={() => handleViewOption(rows[tableMeta.rowIndex])}
                        />
                        <TableEditButton
                            tooltipLabel="Edit"
                            onClick={() => handleRowClick(rows[tableMeta.rowIndex])}
                        />
                        <DeleteButton
                            tooltipLabel="Delete"
                            onDelete={() => onDeleteData(rows[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    function handleRowClick(rowData: any) {
        navigate(`${pathname}/update/${rowData?.uuid}`, { ...rowData });
    }

    function handleViewOption(rowData: any) {
        setViewModal(true);
        setSelectedData(rowData)
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteEmployee({ uuid: data.uuid })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'employee' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout title={formatMessage({ id: "employee" })}>
            <CustomTable
                columns={[...employeeColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
            {viewModal &&
                <View
                    open={viewModal}
                    onClose={() => setViewModal(false)}
                    selectedData={selectedData}
                    // headerText='Employee Detail'
                />
            }

            {viewFlagForm &&
                <FlagForm
                    refreshData={onApiCall}
                    selectedData={selectedData}
                    open={viewFlagForm}
                    closeModal={() => setViewFlagForm(false)}
                    headerText={'Flagged Reason'}
                />
            }

        </PageLayout>
    )
}

export default Employee;
