import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { CacheProvider } from '@emotion/react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDayjs';
import createCache from "@emotion/cache"
import LanguageProvider from './i18n';

import RootProvider from 'utils/context/RootProvider';
import MainRoute from 'routes';
import ThemeProvider from 'theme/theme';
import { persistor, store } from 'redux/store';

import networkInterceptor from 'utils/global/networkInterceptor';

networkInterceptor(store);

export const muiCache = createCache({
  key: 'mui', // all material ui classes start with 'css' instead of 'mui' even with this here
  prepend: true,
});

function App() {
  return (
    <CacheProvider value={muiCache}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <LanguageProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <RootProvider>
                  <MainRoute />
                </RootProvider>
              </LocalizationProvider>
            </LanguageProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CacheProvider>
  );
}

export default App;