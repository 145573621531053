import { MASTER_API_ENDPOINTS } from "redux/masterApiEndpoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any;
}

export const masterServices = {

  createRole: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_ROLE, body),
  updateRole: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_ROLE, body),
  getAllRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_ROLE, body),
  deleteRole: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_ROLE, body),

  createSkill: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_SPOKEN_LANGUAGE, body),
  updateSkill: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_SPOKEN_LANGUAGE, body),
  getAllSkill: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SPOKEN_LANGUAGE, body),
  deleteSkill: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SPOKEN_LANGUAGE, body),

};
