import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

// material
import { Box, Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { CustomButton } from 'components/button';
import { useAsyncDebounce, useToastMessage } from 'hooks';
import { Select } from 'components/forms';
import { monthOption, statusOptions, yearOption } from 'utils/constants';
import { services } from 'redux/services';
import AttendanceExport from './Attendance';
import LeaveExport from './Leave';

const Export: React.FC = () => {
    const { tab }: any = useParams<any>();
    const navigate = useNavigate();
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const [ employeeList ,setEmployeeList] = useState([]);
    const [ employeeId ,setEmployeeId] = useState<any>(null);
    const [tableState, setTableState] = useState<any>({
        limit: 30,
        page: 1,
        sortKey: '',
        sortType: '',
        columnFilter: [],
        searchColumnFilters: [],
        advanceFilter: {
            employee_id: 0,
            year: dayjs().format('YYYY'),
            month: dayjs().format('MM')
        }
    });
    const ref: any = useRef();

    useEffect(() => {
        services.dropdownEmployeeList()
        .then((res: any) => {
            const response = res.result;
            setEmployeeList(response.map((item:any) => ({ value: +item.account_user_id, label: item.full_name_search})))
        })
        .catch((err: any) => {
            toastMessage(err.message, 'error');
        })
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        navigate(`/admin/export/${newValue}`)
    };

    const onChangeSearchQuery = useAsyncDebounce(() => {
        ref?.current?.onApiCall();
    }, 400);

    useEffect(() => {
        if(employeeId){
            setTableState({ ...tableState, advanceFilter: { ...tableState.advanceFilter, employee_id: employeeId?.value } })
        }else{
            setTableState({ ...tableState, advanceFilter: { ...tableState.advanceFilter, employee_id: null } })

        }
    },[employeeId])

    return (
        <TabContext value={tab}>
            <Box sx={{ paddingX: 1, }}>
                <Grid container spacing={2} marginLeft={0} marginBottom={2}>
                    <Grid item xs={12} >
                        <TabList onChange={handleChange} sx={{ background:"#fff"}}>
                            <Tab label="Attendance" value="attendance" />
                            <Tab label="Leave" value="leave" />
                        </TabList>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Select
                            disableClearable
                            options={yearOption}
                            placeholder="Year..."
                            value={yearOption.find(item => +item.value === +tableState.advanceFilter.year)}
                            onChange={(_e, values: any) => setTableState({ ...tableState, advanceFilter: { ...tableState.advanceFilter, year: values?.value } })}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Select
                            disableClearable
                            options={monthOption}
                            placeholder="Month..."
                            value={monthOption.find(item => +item.value === +tableState.advanceFilter.month)}
                            onChange={(_e, values: any) => setTableState({ ...tableState, advanceFilter: { ...tableState.advanceFilter, month: values?.value } })}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Select
                            options={employeeList}
                            placeholder="Employee..."
                            value={employeeId}
                            onChange={(_e, values: any) => setEmployeeId(values)}
                        />
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <CustomButton
                            label='Search'
                            fullWidth
                            onClick={onChangeSearchQuery}
                        />
                    </Grid>
                </Grid>
            </Box>
            <TabPanel value="attendance" sx={{ paddingY: 0 }}>
                <AttendanceExport
                    tableState={tableState}
                    ref={ref}
                    setTableState={setTableState}
                />
            </TabPanel>
            <TabPanel value="leave" sx={{ paddingY: 0 }}>
                <LeaveExport
                   tableState={tableState}
                    ref={ref}
                    setTableState={setTableState}
                />
            </TabPanel>

        </TabContext>
    )
}

export default Export;