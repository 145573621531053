import { BASE_URI } from "utils/axios";

export const API_ENDPOINTS = {
	LOGIN: `${BASE_URI}/login`,
	GET_ACCOUNT: `${BASE_URI}/get-account`,
	SELECT_ACCOUNT: `${BASE_URI}/select-account`,
	UPDATE_PASSWORD: `${BASE_URI}/update-password`,
	LOGOUT: `${BASE_URI}/logout`,
	DASHBOARD: `${BASE_URI}/dashboard`,

	LIST_ACCOUNT: `${BASE_URI}/accounts/list`,
	GET_ACCOUNT_BY_ID: `${BASE_URI}/accounts/get`,
	CREATE_ACCOUNT: `${BASE_URI}/accounts/save`,
	UPDATE_ACCOUNT: `${BASE_URI}/accounts/update`,
	DELETE_ACCOUNT: `${BASE_URI}/accounts/delete`,

	LIST_EMPLOYEE: `${BASE_URI}/employees/list`,
	GET_EMPLOYEE_BY_UUID: `${BASE_URI}/employees/get`,
	CREATE_EMPLOYEE: `${BASE_URI}/employees/save`,
	UPDATE_EMPLOYEE: `${BASE_URI}/employees/update`,
	UPDATE_EMPLOYEE_FLAG: `${BASE_URI}/employees/update-flag`,
	DELETE_EMPLOYEE: `${BASE_URI}/employees/delete`,
	EMPLOYEE_LOOKUP: `${BASE_URI}/employees/lookup`,
	EMPLOYEE_SEARCH: `${BASE_URI}/employees/advance-search`,
	EMPLOYEE_DROPDOWN_LIST: `${BASE_URI}/employees/dropdown-list`,

	LIST_COMPANY: `${BASE_URI}/companies/list`,
	GET_COMPANY_BY_UUID: `${BASE_URI}/companies/get`,
	CREATE_COMPANY: `${BASE_URI}/companies/save`,
	UPDATE_COMPANY: `${BASE_URI}/companies/update`,
	DELETE_COMPANY: `${BASE_URI}/companies/delete`,
	COMPANY_SEARCH: `${BASE_URI}/companies/advance-search`,

	LIST_ATTENDANCE: `${BASE_URI}/attendances/list`,
	GET_ATTENDANCE_BY_UUID: `${BASE_URI}/attendances/get`,
	CREATE_ATTENDANCE: `${BASE_URI}/attendances/save`,
	UPDATE_ATTENDANCE: `${BASE_URI}/attendances/update`,
	DELETE_ATTENDANCE: `${BASE_URI}/attendances/delete`,
	CHECKIN_BUTTON_ATTENDANCE: `${BASE_URI}/attendances/show-check-in-btn`,

	LIST_MENTOR_MENTEE: `${BASE_URI}/employee-client/list`,
	GET_MENTOR_MENTEE: `${BASE_URI}/employee-client/get`,
	CREATE_MENTOR_MENTEE: `${BASE_URI}/employee-client/save`,
	DELETE_MENTOR_MENTEE: `${BASE_URI}/employee-client/delete`,
	MENTOR_MENTEE_LOOKUP: `${BASE_URI}/employee-client/lookup`,

	LIST_AGENT: `${BASE_URI}/clients/list`,
	GET_AGENT_BY_UUID: `${BASE_URI}/clients/get`,
	CREATE_AGENT: `${BASE_URI}/clients/save`,
	UPDATE_AGENT: `${BASE_URI}/clients/update`,
	UPDATE_AGENT_FLAG: `${BASE_URI}/clients/update-flag`,
	DELETE_AGENT: `${BASE_URI}/clients/delete`,
	AGENT_LOOKUP: `${BASE_URI}/clients/lookup`,

	LIST_LEAVE: `${BASE_URI}/leaves/list`,
	GET_LEAVE_BY_UUID: `${BASE_URI}/leaves/get`,
	CREATE_LEAVE: `${BASE_URI}/leaves/save`,
	UPDATE_LEAVE: `${BASE_URI}/leaves/update`,
	DELETE_LEAVE: `${BASE_URI}/leaves/delete`,
	UPDATE_LEAVE_STATUS: `${BASE_URI}/leaves/approved-leave`
};
