

import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { masterPaginationServices } from 'utils/constants';
import { getDateDiff, getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import { leaveExportColumns } from 'utils/constants/tableColumn/column';
import { useCheckRole } from 'hooks';

const LeaveExport = forwardRef(({ tableState, setTableState}: any, ref: any) => {
    const { isAdmin } = useCheckRole();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.leave].data,
            loading: masterPaginationReducer[masterPaginationServices.leave].loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useImperativeHandle(ref, () => ({ onApiCall}));

    useEffect(() => { onApiCall() }, []);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.leave, params));
    }

    const nameColumn = {
        label: formatMessage({ id: "name" }),
        name: "full_name",
    }

    return (
        <CustomTable
            columns={isAdmin ? [nameColumn, ...leaveExportColumns(formatMessage)] : leaveExportColumns(formatMessage)}
            tableData={rows?.map((item:any) => ({
                ...item,
                total_days: getDateDiff(item.start_date,item.end_date)?.days
            }))}
            tableState={tableState}
            rowsCount={count}
            setTableState={setTableState}
            loading={loading}
            hideToolbar
        />
    )
})

export default LeaveExport;
