import dayjs from "dayjs";

export function getApiDate(date: any) {
    let d = dayjs(date).format('YYYY-MM-DDTHH:mm');
    return `${d}:00Z`
}

export function formatApiDate(date: any) {
    if (typeof date === "string") {
        let removeTimeZone = date.replace(':00Z', '');
        return dayjs(removeTimeZone).toDate();
    } else {
        return date;
    }
}

export function getDateDiff(start_date: any, end_date: any) {
    if (dayjs(start_date).isValid() && dayjs(end_date).isValid()) {
        const date1 = dayjs(start_date);
        const date2 = dayjs(end_date);
        const diff = date2.diff(date1, 'day', true);
        const days = Math.floor(diff);
        const hours = Math.floor((diff - days) * 24);
        return { days: days + 1, hours };
    }

    return { days: 0, hours: 0 };
}
