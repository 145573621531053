import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { ButtonGroup, CustomButton } from '../button';
import { Container, styled } from '@mui/system';
import { Close, Save } from '@mui/icons-material';
import Cancel from '@mui/icons-material/Cancel';
import { Modal } from '@mui/material';

interface Props {
    open: boolean;
    children: React.ReactElement | React.ReactElement[];
    title?: string | ReactNode;
    onClose: () => void;
    onSubmit?: () => void;
    cancelLabel?: string | ReactNode;
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const DrawerHeader = styled('div')(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow:'auto'
    // paddingX: 2,
};

const ModalHeader = styled(Typography)(({ theme }: any) => ({
    padding: theme.spacing(2, 2),
    background: theme.palette.primary.lighter,
    display:'flex',
    justifyContent:'space-between'
}));

const CustomModal = (props: Props & DrawerProps) => {
    const { children, title, open, onClose, size, onSubmit } = props;
    let width: any = 400;
    let height:any = 600;
    if (size === 'xs') { width = 300}
    if (size === 'sm') { width = 425}
    if (size === 'md') { width = 800}
    if (size === 'lg') { width = 1190 }
    return (
        <Modal
            open={open}
            hideBackdrop={false}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={size} disableGutters>
                <Box sx={{width, height,...style}}>
                    {title ? <ModalHeader> {title} <Close onClick={onClose}/> </ModalHeader> : null}
                    <div style={{ marginTop: 10,padding:8 }}>
                        {children}
                    </div>
                </Box>
            </Container>
        </Modal>
    )
};

CustomModal.defaultProps = {
    anchor: "right",
    size: 'xs',
    cancelLabel: <FormattedMessage id="close" />
}

export { CustomModal };