import DashboardLayout from "layouts/dashboard";
import {
  Agency,
  AgencyForm,
  Employee,
  EmployeeForm,
  Role,
  Skill,
  Dashboard,
  Setting,
  Client,
  AgentForm,
  Attendance,
  Assigned,
  ChangePassword,
  Leave,
  Export,
} from "pages";
import Login from "pages/Login";
import NotFound from "pages/Page404";
import { Navigate, useRoutes } from "react-router-dom";
import { jobTypeData, roleData } from "utils/constants/role";
import PrivateRoute from "./PrivateRoute";

export default function Router() {
  return useRoutes([
    {
      path: "/:any",
      element: (
        <PrivateRoute roles={[roleData.superadmin, roleData.admin,roleData.employee,roleData.client]}>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin,roleData.employee,roleData.client]}>
              <Dashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "change-password",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin, roleData.employee,roleData.client]}>
              <ChangePassword />
            </PrivateRoute>
          ),
        },
        {
          path: "companies",
          element: (
            <PrivateRoute roles={[roleData.superadmin]} >
              <Agency />
            </PrivateRoute>
          ),
        },
        {
          path: "companies/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <AgencyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "companies/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <AgencyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "assigned",
          element: (
            <PrivateRoute roles={[roleData.support]}>
              <Assigned />
            </PrivateRoute>
          ),
        },
        {
          path: "client-assign",
          element: (
            <PrivateRoute roles={[roleData.client,roleData.employee]} jobType={[jobTypeData.support]}>
              <Assigned />
            </PrivateRoute>
          ),
        },
        {
          path: "clients",
          element: (
            <PrivateRoute roles={[roleData.support]}>
              <Client />
            </PrivateRoute>
          ),
        },
        {
          path: "attendances",
          element: (
            <PrivateRoute roles={[roleData.admin,roleData.client,roleData.employee]}>
              <Attendance />
            </PrivateRoute>
          ),
        },
        {
          path: "clients/:formType",
          element: (
            <PrivateRoute roles={[roleData.support]}>
              <AgentForm />
            </PrivateRoute>
          ),
        },
        {
          path: "clients/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.support]}>
              <AgentForm />
            </PrivateRoute>
          ),
        },
        {
          path: "employees",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Employee />
            </PrivateRoute>
          ),
        },
        {
          path: "employees/:formType",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <EmployeeForm />
            </PrivateRoute>
          ),
        },
        {
          path: "employees/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <EmployeeForm />
            </PrivateRoute>
          ),
        },
        {
          path: "roles",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Role />
            </PrivateRoute>
          ),
        },
        {
          path: "setting",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Setting />
            </PrivateRoute>
          ),
        },
        {
          path: "export/:tab",
          element: (
            <PrivateRoute roles={[roleData.admin]}>
              <Export />
            </PrivateRoute>
          ),
        },
        //  {
        //   path: "profile",
        //   element: (
        //     <PrivateRoute roles={[roleData.employee]}>
        //       <UserProfile />
        //     </PrivateRoute>
        //   ),
        // },
        {
          path: "leave",
          element: (
            <PrivateRoute roles={[roleData.admin,roleData.client,roleData.employee]}>
              <Leave />
            </PrivateRoute>
          ),
        },
        {
          path: "skill",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Skill />
            </PrivateRoute>
          ),
        },
      ],
    },
    { path: "/", element: <Login /> },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
