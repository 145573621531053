import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Grid } from '@mui/material';

import { CustomDatePicker, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { useCheckRole, useToastMessage } from 'hooks';
import { services } from 'redux/services';
import dayjs from 'dayjs';

interface Props {
    open: boolean;
    closeModal: () => void;
    headerText: string;
    refreshData: () => void;
    selectedData: any;
    formType: 'create' | 'update';
}

const FlagForm = (props: Props) => {
    const { closeModal, open, headerText, formType, selectedData, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, setError, control, reset } = useForm<any>({
        mode: 'all',
        defaultValues: {
            start_date: new Date(),
            end_date: new Date(),
        }
    });

    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        if (formType === 'update') data.id = selectedData.id;
        const apiFunc = formType === 'create' ? 'createLeave' : 'updateLeave';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        data.start_date = dayjs(data?.start_date).format('YYYY-MM-DD');
        data.end_date = dayjs(data?.end_date).format('YYYY-MM-DD');
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                closeModal();
                refreshData();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'attendace' }) }));
            })
            .catch((err: any) => {
                if (err.status === 422) {
                    Object.keys(err.data.message).map(key => setError(key, { type: 'custom', message: err.data.message[key] }))
                    toastMessage('Validation error', 'error');
                } else {
                    toastMessage(err.data.message, 'error');
                }
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (selectedData) {
            reset({
                start_date:selectedData?.start_date,
                end_date:selectedData?.end_date,
                remarks: selectedData?.remarks,
            });
        }
    }, [selectedData]);

    return (
        <CustomDrawer
            formMode={formType}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomDatePicker
                        control={control}
                        label={formatMessage({ id: "start-date" })}
                        name="start_date"
                        error={errors.start_date}
                        rules={{ required: true }}
                        minDate={dayjs()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomDatePicker
                        control={control}
                        label={formatMessage({ id: "end-date" })}
                        name="end_date"
                        error={errors.end_date}
                        minDate={dayjs()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "Remarks" })}
                        name="remarks"
                        control={control}
                        minRows={6}
                        rules={{ required: true }}
                        error={errors.remarks}
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default FlagForm;