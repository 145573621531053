import { alpha } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui';

export const useSchedulerStyles = makeStyles()((theme) => ({
    timeTableBlockedCell: {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.2),
        '&:hover': {
            backgroundColor: alpha(theme.palette.action.disabledBackground, 0.15) + " !important",
        },
        '&:focus': {
            backgroundColor: alpha(theme.palette.action.disabledBackground, 0.15) + " !important",
        },
    }
}));

export const useCurrentTimeIndicatorStyles = makeStyles<{ top: number }>()((theme, { top }) => ({
    line: {
        height: "2px",
        width: "100%",
        transform: "translate(0, -1px)"
    },
    circle: {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        borderRadius: "50%",
        transform: "translate(-50%, -50%)"
    },
    nowIndicator: {
        position: "absolute",
        left: 0,
        top,
        background: theme.palette.primary.main,
        zIndex: 1
    }
}));