import React from 'react';
import { IconButtonProps } from '@mui/material/IconButton';
import Button, { ButtonProps } from '@mui/material/Button';
import { useIntl } from 'react-intl';

import { CustomDialog } from 'components/CustomDialog';
import { TableDeleteButton } from './TableButtons';
import { CustomButton } from '.';
import DeleteIcon from '@mui/icons-material/Delete';
interface IconProps extends IconButtonProps {
    tooltipLabel?: string;
    onDelete: () => void;
    title?: string;
    subTitle?: string;
    showConfirmation: boolean;
}


interface LabelProps extends ButtonProps {
    label?: string;
    tooltipLabel?: string;
    onDelete: () => void;
    title?: string;
    subTitle?: string;
    showConfirmation: boolean;
}

const DeleteButton = (props: IconProps) => {
    const [open, setOpen] = React.useState(false);
    const { showConfirmation, title, subTitle, tooltipLabel, onDelete, ...rest } = props;

    const { formatMessage } = useIntl();

    const handleClickOpen = () => {
        if (showConfirmation) {
            setOpen(true);
        } else {
            onDelete();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onAgree = () => {
        onDelete();
        handleClose();
    }

    return (
        <>
            <TableDeleteButton
                tooltipLabel={tooltipLabel ?? "Delete"}
                onClick={handleClickOpen}
                {...rest}
            />

            {showConfirmation && (
                <CustomDialog
                    open={open}
                    onDisagree={handleClose}
                    onAgree={onAgree}
                    title={title ?? formatMessage({ id: "delete-title" })}
                    subTitle={subTitle ?? formatMessage({ id: "delete-confirmation" })}
                />
            )}
        </>
    )
};

DeleteButton.defaultProps = {
    showConfirmation: true
}

const CustomDeleteButton = (props: LabelProps) => {
    const [open, setOpen] = React.useState(false);
    const { showConfirmation, title, subTitle, tooltipLabel, onDelete, ...rest } = props;

    const { formatMessage } = useIntl();

    const handleClickOpen = () => {
        if (showConfirmation) {
            setOpen(true);
        } else {
            onDelete();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onAgree = () => {
        onDelete();
        handleClose();
    }

    return (
        <>
            <Button
                color="error"
                onClick={handleClickOpen}
                startIcon={<DeleteIcon color="inherit" fontSize="inherit" />}
                variant="contained"
                {...rest}
            >
                {rest.label}
                </Button>

            {showConfirmation && (
                <CustomDialog
                    open={open}
                    onDisagree={handleClose}
                    onAgree={onAgree}
                    title={title ?? formatMessage({ id: "delete-title" })}
                    subTitle={subTitle ?? formatMessage({ id: "delete-confirmation" })}
                />
            )}
        </>
    )
};


CustomDeleteButton.defaultProps = {
    showConfirmation: true,
    label: 'Delete'
}

export { DeleteButton, CustomDeleteButton };