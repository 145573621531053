import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Card, Grid } from '@mui/material';
import { CustomDatePicker, CustomSelect } from 'components/forms';
import { useCreateLookupOptions, useToastMessage } from 'hooks';
import { masterLookupServices } from 'utils/constants';
import { services } from 'redux/services';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, ResetButton, SaveButton } from 'components/button';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData } from 'redux/actions';
import dayjs from 'dayjs';

interface Props {
    refreshData?: any;
}

const Form = ({ refreshData }: Props) => {
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, clearErrors, control, reset } = useForm<any>({
        mode: 'all', defaultValues: {
            assigned_at: dayjs().format('YYYY-MM-DD')
        }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    const { employeeLookup } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                employeeLookup: masterLookupReducer[masterLookupServices.getEmployeeClientLookup].data,
            })
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(getMasterLookupData(masterLookupServices.getEmployeeClientLookup));
    }, [])

    const selectOption = useCreateLookupOptions(employeeLookup)

    function onSubmit(data: any) {
        data.employee_id = data?.employee_id?.value;
        data.client_id = data?.client_id?.value;
        data.assigned_at = dayjs(data.assigned_at).format('YYYY-MM-DD');
        services.createEmployeeClient(data)
            .then((res: any) => {
                reset();
                refreshData();
                toastMessage(formatMessage({ id: 'created-message' }, { title: formatMessage({ id: 'employee' }) }));
            })
            .catch((err: any) => {
                toastMessage(err.data.message.employee_id, 'error');
            }).finally(() => setLoading(false))
    }

    function handleReset() {
        reset();
        clearErrors();
    }

    return (
        <Card sx={{ padding: 2, marginBottom: 2, borderRadius: 0 }}>
            <Grid container spacing={2}>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomSelect
                        size="small"
                        options={selectOption?.employee}
                        label={formatMessage({ id: "employee" })}
                        name="employee_id"
                        control={control}
                        error={errors.employee_id}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomSelect
                        size="small"
                        options={selectOption?.client}
                        label={formatMessage({ id: "client" })}
                        name="client_id"
                        control={control}
                        error={errors.client_id}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomDatePicker
                        size="small"
                        control={control}
                        label={formatMessage({ id: "assigned-at" })}
                        name="assigned_at"
                        error={errors.assigned_at}
                        rules={{ required: true }}
                        maxDate={dayjs()}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <ButtonGroup>
                        <ResetButton
                            label='Reset'
                            onClick={handleReset}
                        />
                        <SaveButton
                            label='Assign'
                            onClick={handleSubmit(onSubmit)}
                        />
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Card>
    )
}

export default Form;