import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button, { ButtonProps } from '@mui/material/Button';
import { Edit } from '@mui/icons-material';

interface Props extends ButtonProps {
    label?: string
}

const EditButton = React.memo((props: Props) => {
    const { label, ...rest } = props;
    return (
        <Button
            style={{ marginRight: "10px" }}
            color="primary"
            variant="outlined"
            startIcon={<Edit color="inherit" fontSize="inherit" />}
            {...rest}
        >
            <span>{label ?? <FormattedMessage id="edit" />}</span>
        </Button>
    )
});

export { EditButton };