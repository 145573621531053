import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

// material
import { Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { Container } from '@mui/system';
import { SaveButton } from 'components/button';
import { authReset } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ChangePassword: React.FC = () => {
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSubmit, formState: { errors }, control, watch, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        const message = 'updated-message';
        services.updatePassword(data)
            .then((res: any) => {
                reset();
                dispatch(authReset());
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'password' }) }));
                navigate('/');
            })
            .catch((err: any) => {
                toastMessage(err.message, 'error');
            }).finally(() => setLoading(false))
    }

    return (
        <Container maxWidth="sm">
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "credential" })}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "old-password" })}
                            name="old_password"
                            control={control}
                            error={errors?.old_password}
                            type="password"
                            rules={validationRule.textbox({ required: true, type: 'passwordPolicy' })}
                            autoComplete="off"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                        <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                            <InfoIcon color="primary" />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "password" })}
                            name="password"
                            control={control}
                            error={errors?.password}
                            type="password"
                            rules={{
                                ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                validate: (value: any) => (watch('confirmation_password') ? (watch('confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                            }}
                            autoComplete="off"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                        <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                            <InfoIcon color="primary" />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "confirm-password" })}
                            name="confirmation_password"
                            control={control}
                            error={errors?.confirmation_password}
                            type="password"
                            rules={{
                                ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                            }}
                            autoComplete="off"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SaveButton
                        size='large'
                        fullWidth
                        label={formatMessage({ id: "change-password" })}
                        onClick={handleSubmit(onSubmit)}
                    />
                </Grid>
            </SectionLayout>

        </Container>
    )
}

export default ChangePassword;