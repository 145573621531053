import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Grid, InputAdornment, Tooltip } from '@mui/material';

import { CustomCheckBox, CustomDatePicker, CustomFileUpload, CustomSelect, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { useCreateLookupOptions, useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { genderList, jobTypeOptions, masterLookupServices } from 'utils/constants';
import { services } from 'redux/services';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Info } from '@mui/icons-material';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData } from 'redux/actions';
import dayjs from 'dayjs';
import { validationRule } from 'utils/global';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm<any>({
        mode: 'all', defaultValues: {
            is_active: true,
            job_type: jobTypeOptions[2],
            joining_date:new Date()
        }
    });

    const [fileImage,setFileImage] = useState({
        photo:'',
        document:''
    })

    const { employeeLookup } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                employeeLookup: masterLookupReducer[masterLookupServices.getEmployeeLookup].data,
            })
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(getMasterLookupData(masterLookupServices.getEmployeeLookup));
    }, [])

    const selectOption = useCreateLookupOptions(employeeLookup)

    function onSubmit(data: any) {
        data.cv = data?.cv?.[0];
        data.photo = data?.photo?.[0];
        data.gender_id = data?.gender_id?.value;
        data.job_type = data?.job_type?.value;
        data.joining_date = dayjs(data?.joining_date).format('YYYY-MM-DD');
        data.terminate_date = data?.terminate_date ? dayjs(data?.terminate_date).format('YYYY-MM-DD') : null;
        data.skill = data?.skill?.map((item:any) => +item.value);
        data.is_active = data?.is_active ? 1 : 0;
        if (formType === 'update') data.uuid = uuid;
        const apiFunc = formType === 'create' ? 'createEmployee' : 'updateEmployee';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'employee' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err.message, 'error');
            }).finally(() => setLoading(false))
    }


    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getEmployeeByUuid({ uuid })
                .then((res: any) => {
                    setLoading(false);
                    const response = res.result;
                    reset({
                        ...response,
                        gender_id: genderList.find((item: any) => +item.value === +response.gender_id),
                        job_type: jobTypeOptions.find((item: any) => item.value === response.job_type),
                        skill : response.skills.map((item:any) => ({ label:item.name, value:item.id }))
                    });
                    const photo =  response.profile_photo_url;
                    const document =  response.document_url;
                    setFileImage({photo,document})
                })
                .catch((err: any) => {
                    setLoading(false);
                    toastMessage(err.message, 'error');
                })
        }

    }, [formType === 'update', uuid]);

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "employee" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "general-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "first-name" })}
                            name="first_name"
                            control={control}
                            error={errors?.first_name}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "middle-name" })}
                            name="middle_name"
                            control={control}
                            error={errors?.middle_name}
                            rules={validationRule.textbox({ type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "last-name" })}
                            name="last_name"
                            control={control}
                            error={errors?.last_name}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone-number" })}
                            name="phone"
                            control={control}
                            error={errors?.phone}
                            rules={validationRule.textbox({ required: true, type: "contact", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={genderList}
                            label={formatMessage({ id: "gender" })}
                            name="gender_id"
                            control={control}
                            error={errors.gender_id}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "year-of-expericence" })}
                            name="year_of_expericence"
                            control={control}
                            error={errors?.year_of_expericence}
                            rules={validationRule.textbox({ type: "number", maxLength: 2 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "joining-date" })}
                            name="joining_date"
                            error={errors.joining_date}
                            rules={{ required: true }}
                            maxDate={dayjs()}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "terminate-date" })}
                            name="terminate_date"
                            error={errors.terminate_date}
                            maxDate={dayjs()}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={jobTypeOptions}
                            label={formatMessage({ id: "job-type" })}
                            name="job_type"
                            control={control}
                            error={errors.job_type}
                            rules={{ required: true }}
                        />
                    </Grid>
                    {selectOption?.skill?.length > 0 &&
                    <Grid item  xs={12}>
                        <CustomSelect
                            multiple
                            options={selectOption?.skill}
                            label={formatMessage({ id: "skill" })}
                            name="skill"
                            control={control}
                            error={errors.skill}
                            rules={{ required: true }}
                        />
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <CustomCheckBox
                            name="is_active"
                            label={formatMessage({ id: "is-active" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

            {formType === 'create' &&
                <SectionLayout title={formatMessage({ id: "credential" })}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "login-email" })}
                                name="email"
                                control={control}
                                error={errors?.email}
                                rules={validationRule.textbox({ required: true, type: "email" })}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "password" })}
                                name="password"
                                control={control}
                                error={errors?.password}
                                type="password"
                                rules={{
                                    ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                    validate: (value: any) => (watch('confirmation_password') ? (watch('confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                }}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                            <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                                <Info color="primary" />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "confirm-password" })}
                                name="confirmation_password"
                                control={control}
                                error={errors?.confirmation_password}
                                type="password"
                                rules={{
                                    ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                    validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                }}
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                </SectionLayout>
            }

            <SectionLayout title={formatMessage({ id: "document" })}>
                <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx"
                            label={formatMessage({ id: "photo" })}
                            error={errors?.photo}
                            {...register('photo', {
                                validate: (value: any) => {
                                    const { type, size } = value?.["0"] ?? {};
                                    const isValidSize = +size / 1024 / 1024 >= 2;
                                    if (type && !(type === "image/png" || type === "image/jpg" || type === "image/jpeg")) {
                                        return ".png, .jpg, .jpeg";
                                    } else if (isValidSize) {
                                        return "Maximum size 1 MB";
                                    } else {
                                        return true;
                                    }
                                }
                            })}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`photo`, null);
                                clearErrors(`photo`);
                            }}
                            defaultFileUrl={fileImage.photo}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx"
                            label={formatMessage({ id: "cv" })}
                            error={errors?.cv}
                            {...register('cv', {
                                validate: (value: any) => {
                                    const { type, size } = value?.["0"] ?? {};
                                    const isValidSize = +size / 1024 / 1024 >= 2;
                                    if (type && !(type === "image/png" || type === "image/jpg" || type === "image/jpeg" || type === "application/pdf" || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/pdf' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                                        return ".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx supported only";
                                    } else if (isValidSize) {
                                        return "Maximum size 1 MB";
                                    } else {
                                        return true;
                                    }
                                }
                            })}
                            defaultFileUrl={fileImage.document}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`cv`, null);
                                clearErrors(`cv`);
                            }}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>
        </FormLayout>
    )
}

export default Form;