import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Cancel from "@mui/icons-material/Cancel";
import Visibility from "@mui/icons-material/Visibility";
import { getUpdateImageFormat, validationRule } from 'utils/global';

import { FormErrorMessage } from "./FormErrorMessage";
import { colorList } from "assets/styles/mui";
import { images } from "utils/constants";

interface Props {
    error?: any;
    onClear?: () => void;
    onViewImage?: (file: any) => void;
    defaultFileName?: string | undefined;
    defaultFileUrl?: string;
    label?: string;
    formLabel?: string;
    fileData?: any;
    isPreviewImage?: boolean;
}

export const CustomFileUpload = React.forwardRef<
    HTMLInputElement,
    Props & React.HTMLProps<HTMLInputElement>
>((props, ref) => {
    const {
        error,
        label,
        onClear,
        defaultFileName,
        fileData,
        isPreviewImage,
        onViewImage,
        accept,
        defaultFileUrl,
        formLabel,
        ...rest
    } = props;
    const [fileName, setFileName] = useState<string | undefined>(
        defaultFileName ?? undefined
    );
    const [file, setFile] = useState<any>(null);
    const [filePreview, setFilePreview] = useState<any>(null);

    function fileRender() {
        let objectUrl = "";
        let previewImage = "";
        if (file?.[0]) {
            const split = file?.[0]?.name?.split(".");
            if (["pdf", "xls", "xlsx", "docx"].includes(split?.pop())) {
                objectUrl = URL.createObjectURL(file[0]);
                previewImage = images.document;
            } else {
                objectUrl = URL.createObjectURL(file[0]);
                previewImage = URL.createObjectURL(file[0]);
            }
        } else if (defaultFileUrl) {
            objectUrl = defaultFileUrl;
        }

        return { objectUrl, previewImage };
    }

    function onViewFile() {
        const fileData = fileRender();
        window.open(fileData.objectUrl, "_blank");
    }

    useEffect(() => {
        if (file) {
            const fileData = fileRender();
            setFilePreview(fileData.previewImage)
        } else {
            setFilePreview(null)
        }
    }, [file])

    useEffect(() => {
        if(defaultFileUrl) {
            const fileData= getUpdateImageFormat(defaultFileUrl);
            setFilePreview(fileData.previewImage);
            setFileName(fileData.fileName)
        }
    },[defaultFileUrl])

    return (
        <FormControl fullWidth>
            {formLabel && <div style={{ marginBottom: 8 }}>{formLabel}</div>}
            <Button
                fullWidth
                component="label"
                variant="outlined"
                disableElevation
                disableFocusRipple
                startIcon={
                    fileName ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                                size="small"
                                style={{ margin: 0, marginRight: 7 }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onViewFile();
                                    if (onViewImage) {
                                        onViewImage(file);
                                    }
                                }}
                            >
                                <Visibility color="secondary" />
                            </IconButton>
                            <label style={{ opacity: 0.6 }}>
                                {fileName
                                    ? fileName
                                        ?.substring(0, 20)
                                        ?.replace(/[\s\n]+$/, "")
                                        ?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, "") + "..."
                                    : label}
                            </label>
                        </div>
                    ) : (
                        <label style={{ opacity: 0.6 }}>{label}</label>
                    )
                }
                endIcon={
                    fileName ? (
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.preventDefault();
                                setFileName(undefined);
                                setFile(null);
                                setFilePreview(null);
                                if (onClear) {
                                    onClear();
                                }
                            }}
                        >
                            <Cancel color="error" />
                        </IconButton>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                color: colorList.secondaryDark,
                                backgroundColor: colorList.secondaryLight,
                                padding: 10,
                                marginRight: -15,
                            }}
                        >
                            <span style={{ fontSize: 12, marginRight: 5 }}>Browse</span>
                        </div>
                    )
                }
                style={{
                    justifyContent: "space-between",
                    height: 40,
                    textTransform: "none",
                    fontSize: 12,
                    borderRadius: 4,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
            >
                <input
                    type="file"
                    hidden
                    accept={accept}
                    ref={ref}
                    {...rest}
                    onChange={(e) => {
                        if (e.target?.files) {
                            setFile(e.target.files);
                            setFileName(e.target.files?.[0]?.name);
                        }
                        if (rest?.onChange) {
                            rest.onChange(e);
                        }
                    }}
                />
            </Button>
            {!error && (
                <span style={{ fontSize: 12, marginTop: 5, color: "rgba(0,0,0,0.5)" }}>
                    ({accept} supported only)
                </span>
            )}

            {isPreviewImage && filePreview && (
                <img
                    src={filePreview}
                    style={{ marginTop: 20, height: 150, width: 150 }}
                />
            )}

            {/* error message */}
            <FormErrorMessage error={error} />
        </FormControl>
    );
});

CustomFileUpload.defaultProps = {
    accept: ".png, .jpg, .jpeg",
    label: "Choose file",
    isPreviewImage: true,
};
export default CustomFileUpload;
