import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Grid, InputAdornment, Tooltip } from '@mui/material';

import { CustomCheckBox, CustomSelect, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { getUpdateImageFormat, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { genderList, images } from 'utils/constants';
import { services } from 'redux/services';
import { Info } from '@mui/icons-material';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const navigate = useNavigate();
    const { handleSubmit, formState: { errors }, control, watch, reset } = useForm<any>({
        mode: 'all', defaultValues: {
            is_active: true
        }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState({
        objectUrl: "",
        previewImage: "",
    });

    function onSubmit(data: any) {
        data.photo = data?.photo?.[0];
        data.gender_id = data?.gender_id?.value;
        data.is_active = data?.is_active ? 1 : 0;
        if (formType === 'update') data.uuid = uuid;
        const apiFunc = formType === 'create' ? 'createAgent' : 'updateAgent';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'client' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err.message, 'error');
            }).finally(() => setLoading(false))
    }

    useEffect(() => {
        let objectUrl = "";
        let previewImage = "";
        if (watch('photo')?.[0]) {
            const split = watch('photo')?.[0]?.name?.split('.');
            if (['pdf', 'xls', 'xlsx', 'docx'].includes(split?.pop())) {
                objectUrl = URL.createObjectURL(watch('photo')?.[0]);
                previewImage = images.document;
            } else {
                objectUrl = URL.createObjectURL(watch('photo')?.[0]);
                previewImage = URL.createObjectURL(watch('photo')?.[0]);
            }
        } 
        setFileData({ objectUrl, previewImage})
    }, [watch('photo')?.[0]])


    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getAgentByUuid({ uuid })
                .then((res: any) => {
                    setLoading(false);
                    const response = res.result;
                    reset({
                        ...response,
                        gender_id: genderList.find(item => +item.value === +response.gender_id),
                    });
                    setFileData(getUpdateImageFormat(response.profile_photo_url))
                })
                .catch((err: any) => {
                    setLoading(false);
                    toastMessage(err.message, 'error');
                })
        }
    }, [formType === 'update', uuid]);


    return (
        <FormLayout formType={formType} title={formatMessage({ id: "client" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "general-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "first-name" })}
                            name="first_name"
                            control={control}
                            error={errors?.first_name}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "middle-name" })}
                            name="middle_name"
                            control={control}
                            error={errors?.middle_name}
                            rules={validationRule.textbox({ type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "last-name" })}
                            name="last_name"
                            control={control}
                            error={errors?.last_name}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone-number" })}
                            name="phone"
                            control={control}
                            error={errors?.phone}
                            rules={validationRule.textbox({ required: true, maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={genderList ?? []}
                            label={formatMessage({ id: "gender" })}
                            name="gender_id"
                            control={control}
                            error={errors.gender_id}
                            rules={{ required: true }}
                        />
                    </Grid>
                    {/* <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "dob" })}
                            name="dob_ad"
                            error={errors.dob_ad}
                            rules={{
                                required: true,
                                validate: (value: any) => (dayjs().diff(value, 'year') >= 18) || formatMessage({ id: "age-validation-message-18" })
                            }}
                            onChangeValue={(date: any) => date ? setAge(dayjs().diff(date, 'year')) : null}
                            maxDate={new Date()}
                        />
                    </Grid> */}

                    <Grid item xs={12}>
                        <CustomCheckBox
                            name="is_active"
                            label={formatMessage({ id: "is-active" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>
            
            {formType === 'create' &&
                <SectionLayout title={formatMessage({ id: "credential" })}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "login-email" })}
                                name="email"
                                control={control}
                                error={errors?.email}
                                rules={validationRule.textbox({ required: true, type: "email" })}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "password" })}
                                name="password"
                                control={control}
                                error={errors?.password}
                                type="password"
                                rules={{
                                    ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                    validate: (value: any) => (watch('confirmation_password') ? (watch('confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                }}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                            <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                                <Info color="primary" />
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "confirm-password" })}
                                name="confirmation_password"
                                control={control}
                                error={errors?.confirmation_password}
                                type="password"
                                rules={{
                                    ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                    validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                }}
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                </SectionLayout>
            }

            {/* <SectionLayout title={formatMessage({ id: "document" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx"
                            label="Photo"
                            error={errors?.photo}
                            {...register('photo', {
                                validate: (value: any) => {
                                    const { type, size } = value?.["0"] ?? {};
                                    const isValidSize = +size / 1024 / 1024 >= 2;
                                    if (type && !(type === "image/png" || type === "image/jpg" || type === "image/jpeg" || type === "application/pdf" || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/pdf' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                                        return ".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx supported only";
                                    } else if (isValidSize) {
                                        return "Maximum size 2 MB";
                                    } else {
                                        return true;
                                    }
                                }
                            })}
                            fileData={fileData}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`photo`, null);
                                clearErrors(`photo`);
                            }}
                        />
                    </Grid>
                </Grid>
            </SectionLayout> */}

        </FormLayout>
    )
}

export default Form;