import { BASE_URI } from "utils/axios";

export const MASTER_API_ENDPOINTS = {
	// Master Requests
	LIST_ROLE: `${BASE_URI}/roles/list`,
	CREATE_ROLE: `${BASE_URI}/roles/save`,
	UPDATE_ROLE: `${BASE_URI}/roles/update`,
	DELETE_ROLE: `${BASE_URI}/roles/delete`,

	LIST_SPOKEN_LANGUAGE: `${BASE_URI}/skills/list`,
	CREATE_SPOKEN_LANGUAGE: `${BASE_URI}/skills/save`,
	UPDATE_SPOKEN_LANGUAGE: `${BASE_URI}/skills/update`,
	DELETE_SPOKEN_LANGUAGE: `${BASE_URI}/skills/delete`,

};
