import React from 'react'
import { Controller, ControllerProps, FieldError } from "react-hook-form";
import { FormErrorMessage, } from "components/forms";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from '@emotion/styled';


const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }]
    ]
}

const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
    'link',
    'align',
    'color', 'background'
]

interface Props {
    name: string;
    rules?: ControllerProps["rules"];
    defaultValue?: any;
    control: ControllerProps["control"];
    error?: FieldError;
    formLabel?: string;
    height?: string;
}

const EditorContainerStyle = styled('div')(({ theme }:any) => ({
    overflow: "hidden",
    borderBottom:`1px solid ${theme.palette.action.hover}`
  }));

const CustomTextEditor = React.memo(((props: Props) => {
    const { name, control, rules, defaultValue, error, formLabel, height = "250px" } = props;

    return (
       <EditorContainerStyle sx={{ height }}>
            {formLabel && <div className="text-13 font-medium" style={{ marginBottom: "8px" }}>{formLabel}</div>}
            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue ?? null}
                render={({ field: { ref, ...fieldRest } }) => (
                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        style={{ height: "100%" }}
                        formats={formats}
                        {...fieldRest}
                    />
                )}
            />

            <FormErrorMessage error={error} />
        </EditorContainerStyle>
    )
}));

export { CustomTextEditor };