import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import { Drawer, } from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';

// components
import Logo from './Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import SidebarMenu from 'utils/constants/menu';
import { useIntl } from 'react-intl';

const LogoStyle = styled('div')(({ theme }: any) => ({
  padding: '0 24px',
  display: 'inline-flex',
  // padding: theme.spacing(2, 2.5),
  borderBottom: `1px solid ${theme.palette.action.hover}`
}));


export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, toogleSidebar }: any) {
  const isDesktop = useResponsive('up', 'lg');
  const {formatMessage} = useIntl();
  const DRAWER_WIDTH = toogleSidebar ? 230 : 0;

  const RootStyle = styled('div')(({ theme }: any) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: DRAWER_WIDTH,
    },
  }));

  const { pathname } = useLocation();


  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <LogoStyle>
        <Logo disabledLink/>
      </LogoStyle>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}
      {toogleSidebar &&
        <NavSection navConfig={SidebarMenu(formatMessage)} />
      }
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
