import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Grid } from '@mui/material';

import { CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { useCheckRole, useToastMessage } from 'hooks';
import { services } from 'redux/services';
import dayjs from 'dayjs';

interface Props {
    open: boolean;
    closeModal: () => void;
    headerText: string;
    refreshData: () => void;
    selectedData: any;
    formType: 'create' | 'update';
}

const FlagForm = (props: Props) => {
    const { closeModal, open, headerText,formType, selectedData ,refreshData} = props;
    const { toastMessage } = useToastMessage();
    const { isAdmin,isSupport } = useCheckRole();
    const { handleSubmit, formState: { errors },setError, control, reset } = useForm<any>({
        mode: 'all',
        defaultValues: {
            check_in: '21:00',
            check_out: '23:00',
        }
    });

    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        if (formType === 'update') data.id = selectedData.id;
        const apiFunc = formType === 'create' ? 'createAttendance' : 'updateAttendance';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                closeModal();
                refreshData();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'attendace' }) }));
            })
            .catch((err: any) => {
                if(err.status === 422){
                    Object.keys(err.data.message).map(key => setError(key,{ type: 'custom', message: err.data.message[key] }))
                    toastMessage('Validation error', 'error');
                }else{
                    toastMessage(err.data.message, 'error');
                }
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (selectedData) {
            reset({
                check_in: dayjs(selectedData?.check_in).format('HH:mm'),
                check_out: dayjs(selectedData?.check_out).format('HH:mm'),
                remarks: selectedData?.remarks,
            });
        }
    }, [selectedData]);

    return (
        <CustomDrawer
            formMode={'update'}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        control={control}
                        id="time"
                        type="time"
                        label={formatMessage({ id: "check-in" })}
                        name="check_in"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        error={errors.check_in}
                        disabled={isSupport ? false : true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        control={control}
                        id="time"
                        type="time"
                        label={formatMessage({ id: "check-out" })}
                        name="check_out"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        error={errors.check_out}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "Remarks" })}
                        name="remarks"
                        control={control}
                        minRows={6}
                        rules={{ required: true }}
                        error={errors.remarks}
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default FlagForm;