import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { ButtonProps } from '@mui/material/Button';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { CustomButton } from '.';

interface Props extends ButtonProps {
    label?: string
}

const ResetButton = React.memo((props: Props) => {
    const { label, ...rest } = props;

    return (
        <Button
            startIcon={<RotateLeftIcon color="inherit" fontSize="inherit" />}
            variant="outlined"
            color="error"
            {...rest}
        >
            <span>{label ?? <FormattedMessage id="cancel" />}</span>
        </Button>
    )
});

export { ResetButton };