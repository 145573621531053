import AccessDenied from 'pages/AccessDenied';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { findCommonItems } from 'utils/global';
import { RootReducerState } from 'utils/types';

const PrivateRoute = ({ children, roles,jobType }: { children: JSX.Element ,roles: Array<string>,jobType?:any}) => {
  let location = useLocation();

  const { data } = useSelector(({ auth }: RootReducerState) => ({ data: auth.data,loading: auth.loading  }));

  const userHasRequiredRole = jobType?.length > 0 ? findCommonItems(roles,data.roles) && jobType.includes(data.job_type) : findCommonItems(roles,data.roles);

  if (!data?.token) {
    return <Navigate to="/" state={{ from: location }} />;
  }


  if (data?.token && !userHasRequiredRole) {
    return <AccessDenied />; // build your won access denied page (sth like 404)
  }


  return children;
};

export default PrivateRoute;