import { services } from "redux/services";

const check = <T extends string>(
  collection: Record<T, keyof typeof services>
) => collection;

export const masterPaginationServices = check({
  account: "getAllAccount",
  employee: "getAllEmployee",
  company: "getAllCompany",
  role: "getAllRole",
  skill: "getAllSkill",
  client: "getAllAgent",
  attendance: "getAllAttendance",
  leave: "getAllLeave",
  employeeClient:"getAllEmployeeClient",
  specificEmployeeClient:'getSpecificEmployeeClient'
});
