import { DefaultReducerListType, DefaultReducerObjectType, TableFilterStateType, PaginationReducerType, AuthReducerObjectType, RecentRowReducerType } from "utils/types";

export const genderTypeOptions = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Both', value: 3 },
];

export const jobTypeOptions = [
  { label: 'Full Time', value: 'full_time' },
  { label: 'Part Time', value: 'part_time' },
  { label: 'Support', value: 'support' },
];

export const constants = {
  df: "YYYY-MM-DD",
  dtf: "YYYY-MM-DD hh:mm A",
  tfAmPM: "hh:mm A",
  tsf: "hh:mm:ss",
  t24hf: "HH:mm",
  t24hsf: "HH:mm:ss",
}

export const callType = [
  { label: 'Enquiry', value: "1" },
  { label: 'Appointment', value: "2" },
];

export const tableInitialState: TableFilterStateType = {
  limit: 10,
  page: 1,
  sortKey: '',
  sortType: '',
  columnFilter: [],
  searchColumnFilters: [],
}

export const filterTypes = {
  "text": "STRING",
  "number": "NUMBER",
  "date": "DATE",
  "select": "SELECT",
  "multiSelect": "MULTI-SELECT",
  "boolean": "BOOLEAN",
  "custom": "CUSTOM"
}

export const filterOperators = {
  "asc": 0,
  "desc": 1,
  "isLessThan": 0,
  "isLessThanOrEqualTo": 1,
  "isEqualTo": 2,
  "isNotEqualTo": 3,
  "isGreaterThanOrEqualTo": 4,
  "isGreaterThan": 5,

  "startsWith": 6,
  "endsWith": 7,
  "contains": 8,
  "isContainedIn": 9,
  "doesNotContain": 10,
}

export const numberFiltersLists = [
  { label: "Is Less Than", operator: filterOperators.isLessThan },
  { label: "Is Less Than Or Equal To", operator: filterOperators.isLessThanOrEqualTo },
  { label: "Is Equal To", operator: filterOperators.isEqualTo },
  { label: "Is Not Equal To", operator: filterOperators.isNotEqualTo },
  { label: "Is Greater Than Or Equal To", operator: filterOperators.isGreaterThanOrEqualTo },
  { label: "Is Greater Than", operator: filterOperators.isGreaterThan },
];

export const dateFiltersLists = numberFiltersLists;

export const textFiltersLists = [
  { label: "Starts With", operator: filterOperators.startsWith },
  { label: "Ends With", operator: filterOperators.endsWith },
  { label: "Contains", operator: filterOperators.contains },
  { label: "Is Contained In", operator: filterOperators.isContainedIn },
  { label: "Does Not Contain", operator: filterOperators.doesNotContain }
];

export const defaultArrayState: DefaultReducerListType = {
  loading: false,
  error: false,
  data: [],
};

export const defaultObjectState: DefaultReducerObjectType = {
  loading: false,
  error: false,
  data: {},
};

export const AuthObjectState: AuthReducerObjectType = {
  loading: false,
  error: false,
  data: {},
  status: 0
};

export const defaultPaginationState: PaginationReducerType = {
  loading: false,
  error: false,
  data: {
    modelItems: [],
    totalRecord: 0
  },
};

export const defaultRecentRowState: RecentRowReducerType = {
  loading: false,
  error: false,
  data: {
    recentModelItems: [],
  },
};

export const genderList = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Both', value: 3 },
];

export const roleList = [
  { label: 'Super Admin', value: 'superadmin' },
  { label: 'Admin', value: 'admin'},
  { label: 'Support', value: 'support'},
];

export const booleanTypes = {
  Yes: true,
  No: false
}

export const positiveNegativeOptions = [
  { label: "Positive", value: 1 },
  { label: "Negative", value: 2 }
]

export const yesNoOptions = [
  { label: "Yes", value: 1 },
  { label: "No", value: 2 }
]

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]

export const statusOptions = [
  { label: "In Active", value: "0", color: "#FF0000" },
  { label: "Active", value: "1", color: "#008000" },
]

export const leaveStatusOptions = [
  { label: "Pending", value: "0", color: "#FF0000" },
  { label: "Approved", value: "1", color: "#008000" },
]

export const monthOption = [
  { label: "January", value: 1 },
  { label: "Februry", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9},
  { label: "October", value: 10 },
  { label: "November", value: 11},
  { label: "December", value: 12},
]

export const yearOption = [
  { label: "2021", value: 2021 },
  { label: "2022", value: 2022 },
  { label: "2023", value: 2023 },
  { label: "2024", value: 2024 },
  { label: "2025", value: 2025 },
  { label: "2026", value: 2026 },
  { label: "2027", value: 2027 },
  { label: "2028", value: 2028},
  { label: "2029", value: 2029 },
  { label: "2030", value: 2030},
]
