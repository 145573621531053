import { services } from "redux/services";

const check = <T extends string>(
	collection: Record<T, keyof typeof services>
) => collection;

export const masterLookupServices = check({
	getEmployeeLookup:'getEmployeeLookup',
	getAgentLookup:'getAgentLookup',
	getEmployeeClientLookup:'getEmployeeClientLookup',
});