import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// material
import { Box, Button, Card, Grid, Stack } from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { CustomCheckBox, CustomFileUpload, CustomTextArea, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { RootReducerState } from 'utils/types';
import { Container } from '@mui/system';
import { Save } from '@mui/icons-material';

const Form: React.FC = () => {
    const { formType }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();

    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [updateId, setUpdateId] = useState<number>(0);
    const [fileImage, setFileImage] = useState({
        logo: '',
    })

    const { authData } = useSelector(({ auth }: RootReducerState) => {
        return ({
            authData: auth.data
        })
    },
        shallowEqual
    );

    function onSubmit(data: any) {
        setLoading(true);
        data.logo = data?.logo?.[0];
        data.id = updateId;
        data.uuid = authData.account_uuid;
        const apiFunc = 'updateAccountByUuid';
        const message = 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'company' }) }));
            })
            .catch((err: any) => {
                toastMessage(err.message, 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (authData?.account_uuid) {
            // setLoading(true);
            services.getAccountByUuid({ uuid: authData.account_uuid })
                .then((res: any) => {
                    const response = res.result;
                    setUpdateId(+response.id);
                    reset({
                        general_information: {
                            name: response.name,
                            short_description: response.short_description,
                            phone: response.phone,
                            contact: response.contact,
                            email: response.email,
                            is_active: response.is_active,
                            description: response.description,
                        },
                        address: {
                            address_line1: response.address_line1,
                            address_line2: response.address_line2,
                            state: response.state,
                            zip: response.zip,
                        },
                    });
                    setFileImage({ logo: response.logo_path })
                })
                .catch((err: any) => {
                    toastMessage(err.message, 'error');
                })
                .finally(() => setLoading(false))
        }

    }, [formType === 'update', authData?.account_uuid]);

    return (
        <Container maxWidth="xl">
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "profile-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "company-name" })}
                            name="general_information.name"
                            control={control}
                            error={errors?.general_information?.name}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>

                    <Grid item lg={8} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "slogan" })}
                            name="general_information.short_description"
                            control={control}
                            error={errors?.general_information?.slogan}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone-number" })}
                            name="general_information.phone"
                            control={control}
                            error={errors?.general_information?.phone}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "contact" })}
                            name="general_information.contact"
                            control={control}
                            error={errors?.general_information?.contact}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="general_information.email"
                            control={control}
                            error={errors?.general_information?.email}
                            rules={validationRule.textbox({ type: "email", required: true })}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "company-detail" })}
                            name="general_information.description"
                            control={control}
                            minRows={6}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomCheckBox
                            name="general_information.is_active"
                            label={formatMessage({ id: "is-active" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>
            <SectionLayout title={formatMessage({ id: "profile-address" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address-line1" })}
                            name="address.address_line1"
                            control={control}
                            error={errors?.address?.address_line1}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address-line2" })}
                            name="address.address_line2"
                            control={control}
                            error={errors?.address?.address_line2}
                            rules={validationRule.textbox({ type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "zip" })}
                            name="address.zip"
                            control={control}
                            error={errors?.address?.zip}
                            rules={validationRule.textbox({ type: "number" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "country" })}
                            name="address.country"
                            control={control}
                            error={errors?.address?.country}
                            defaultValue="Nepal"
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                            disabled
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

            <SectionLayout title={formatMessage({ id: "profile-files" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx"
                            label="Choose logo"
                            error={errors?.logo}
                            {...register('logo', {
                                validate: (value: any) => {
                                    const { type, size } = value?.["0"] ?? {};
                                    const isValidSize = +size / 1024 / 1024 >= 2;
                                    if (type && !(type === "image/png" || type === "image/jpg" || type === "image/jpeg" || type === "application/pdf" || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/pdf' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                                        return ".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .docx supported only";
                                    } else if (isValidSize) {
                                        return "Maximum size 2 MB";
                                    } else {
                                        return true;
                                    }
                                }
                            })}
                            defaultFileUrl={fileImage.logo}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`logo`, null);
                                clearErrors(`logo`);
                            }}
                        />
                    </Grid>
                </Grid>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" my={5}>
                    <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)} startIcon={<Save />}>
                        Submit
                    </Button>
                </Stack>
            </SectionLayout>
        </Container>
    )
}

export default Form;