import { shallowEqual, useSelector } from 'react-redux';
import { roleData } from 'utils/constants/role';
import { findCommonItems } from 'utils/global';
import { RootReducerState } from 'utils/types';

export function useCheckRole() {
    const { authData } = useSelector(
        ({ auth }: RootReducerState) => ({
            authData: auth.data,
            loading: auth.loading,
        }),
        shallowEqual
    );

    const isAdmin = findCommonItems([roleData.admin],authData.roles);
    const isEmployee = findCommonItems([roleData.employee],authData.roles);
    const isClient = findCommonItems([roleData.client],authData.roles);
    const isSuperAdmin = findCommonItems([roleData.superadmin],authData.roles);
    const isFullTime = authData.job_type === 'full_time' ? true:false;
    const isPartTime = authData.job_type === 'part_time' ? true:false;
    const isSupport = authData.job_type === 'support' ? true:false;

    return {
        isAdmin,
        isEmployee,
        isClient,
        isSuperAdmin,
        isFullTime,
        isPartTime,
        isSupport
    }
}