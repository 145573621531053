import { useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';

import Box from "@mui/material/Box";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

type TabContainerProps = {
    onChange?: (value: number) => void;
    tabs: string[];
    height?: string;
} & Omit<TabsProps, "onChange">;

const useStyles = makeStyles()((theme) => ({
    customTabRoot: {
        color: theme.palette.primary.dark,
        minHeight: "38px",
        height: "38px",
    },
    customTabIndicator: {
        height: "0px"
    },
    tabRoot: {
        textTransform: "unset",
        minHeight: "38px",
        height: "38px",
        transition: 'all 0.3s ease-in',
        color: theme.palette.text.primary,
        borderRight: `1px solid ${theme.palette.secondary.light}`,
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
            // color: theme.palette.text.primary,
        }
    },
    activeTabRoot: {
        background: `${theme.palette.secondary.light} !important`,
        color: `${theme.palette.secondary.dark} !important`,
        fontWeight: "bolder"
    }
})
);


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        className: 'text-13 font-regular'
    }
}

const CustomTab = ({ onChange, tabs, height, ...rest }: TabContainerProps) => {
    const { classes } = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const { formatMessage } = useIntl();

    return (
        <Box marginBottom={1} style={{ overflow: "hidden", borderRadius: 5, background: "#FFF" }} boxShadow={1}>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={activeTab}
                onChange={(_: any, value: number) => {
                    setActiveTab(value);
                    if (onChange) {
                        onChange(value);
                    }
                }}
                classes={{
                    root: classes.customTabRoot,
                    indicator: classes.customTabIndicator,
                }}
                {...rest}
            >
                {tabs.map((tab, index) => (
                    <Tab key={tab} label={formatMessage({ id: tab })} {...a11yProps(index)} classes={{ root: classes.tabRoot, selected: classes.activeTabRoot + " font-medium" }} />
                ))}
            </Tabs>
        </Box >
    )
}

export { CustomTab };