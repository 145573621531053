import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { ButtonGroup } from "components/button";

interface Props {
  children:
    | React.ReactNode
    | React.ReactElement
    | React.ReactNode[]
    | React.ReactElement[];
  meta?: any;
  title?: string;
  hideToolbar?: boolean;
  isList?: boolean;
  onCreate?: () => void;
  customToolBar?: any;
}

const PageLayout = forwardRef(
  (
    {
      children,
      isList,
      title,
      hideToolbar,
      meta,
      customToolBar,
      onCreate,
      ...other
    }: Props,
    ref: any
  ) => {
    const { formatMessage } = useIntl();
    const nagivate = useNavigate();
    const match = useLocation();
    return (
      <>
        <Helmet>
          <title>{`${title} | iCodfiy Hr`}</title>
          {meta}
        </Helmet>

        <Box ref={ref} {...other}>
          <Container maxWidth="xl" style={{ maxWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="h5" color="primary" gutterBottom>
                {title} {isList && formatMessage({ id: "list" })}
              </Typography>
              {customToolBar && customToolBar}
              {!hideToolbar && (
                <Button
                  variant="contained"
                  onClick={() =>
                    onCreate ? onCreate() : nagivate(`${match.pathname}/create`)
                  }
                  startIcon={<Add />}
                >
                  Create
                </Button>
              )}
            </Stack>
            {children}
          </Container>
        </Box>
      </>
    );
  }
);

PageLayout.defaultProps = {
  title: "",
  hideToolbar: false,
  isList: true,
};

export default PageLayout;
