import Dashboard from '@mui/icons-material/Dashboard';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import GroupIcon from '@mui/icons-material/Group';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { PanTool, PersonAdd, Settings } from '@mui/icons-material';
import AttributionIcon from '@mui/icons-material/Attribution';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import { jobTypeData, roleData } from './role';

export interface MenuType {
  title:string;
  path:string;
  icon:any;
  role:Array<string> | undefined;
  jobType?:Array<string> | undefined;
  children?:MenuType[] | undefined
}

const SidebarMenu = (formatMessage: any):MenuType[] => [
  {
    title: formatMessage({ id: 'dashboard' }),
    path: '/dashboard',
    icon: <Dashboard />,
    role: [roleData.superadmin, roleData.admin, roleData.employee, roleData.client],
  },
  {
    title: formatMessage({ id: 'company' }),
    path: '/companies',
    icon: <MapsHomeWorkIcon />,
    role: [roleData.superadmin]
  },
  {
    title: formatMessage({ id: 'attendance' }),
    path: '/attendances',
    icon: <PanTool />,
    role: [roleData.admin, roleData.employee, roleData.client],
  },
  {
    title: formatMessage({ id: 'leave' }),
    path: '/leave',
    icon: <SettingsAccessibilityIcon />,
    role: [roleData.admin, roleData.employee, roleData.client],
  },
  {
    title: formatMessage({ id: 'assigned' }),
    path: '/assigned',
    icon: <PersonAdd />,
    role: [roleData.support]
  },
  {
    title: formatMessage({ id: 'support' }),
    path: '/client-assign',
    icon: <PersonAdd />,
    role: [roleData.employee, roleData.client],
    jobType:[jobTypeData.support]
  },
  {
    title: formatMessage({ id: 'employee' }),
    path: '/employees',
    icon: <EngineeringIcon />,
    role: [roleData.admin]
  },
  // {
  //   title: 'Company',
  //   path: '/companies',
  //   icon: <WorkIcon />,
  //   role: [roleData.admin]
  // },
  {
    title: formatMessage({ id: 'client' }),
    path: '/clients',
    icon: <AttributionIcon />,
    role: [roleData.support]
  },
  // {
  //   title: 'User',
  //   path: '/users',
  //   icon: <GroupIcon />,
  //   role: [roleData.superadmin]
  // },
  {
    title: formatMessage({ id: 'setting' }),
    path: '/setting',
    icon: <Settings />,
    role: [roleData.admin],
  },
  {
    title: formatMessage({ id: 'export' }),
    path: '/export/attendance',
    icon: <ImportExportIcon />,
    role: [roleData.admin],
  },
  // {
  //   title: formatMessage({ id: 'profile' }),
  //   path: '/profile',
  //   icon: <EngineeringIcon />,
  //   role: [roleData.employee],
  // },
  {
    title: formatMessage({ id: 'master' }),
    path: '#',
    icon: <Settings />,
    role: [roleData.superadmin],
    children: [
      {
        title: formatMessage({ id: 'role' }),
        path: '/roles',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      {
        title: formatMessage({ id: 'skill' }),
        path: '/skill',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      }
    ]
  },

];

export default SidebarMenu;
