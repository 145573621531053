
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, TableButtonGroup, TableViewButton, TableFlagButton, EditButton } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { attendanceColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useCheckRole, useToastMessage } from 'hooks';
import Form from './Form';
import { Button } from '@mui/material';
import { Fingerprint } from '@mui/icons-material';
import dayjs from 'dayjs';

const Employee: React.FC = () => {
    const { isAdmin, isSupport, isFullTime,isPartTime } = useCheckRole();
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();
    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [viewForm, setViewForm] = useState(false);
    // const [showCheckIn, setShowCheckIn] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.attendance].data,
            loading: masterPaginationReducer[masterPaginationServices.attendance].loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();

    }, [tableState]);


    // function checkInButtonApiCall() {
    //     services.checkInAttendance().then((res: any) => {
    //         if (res.result) setShowCheckIn(true);
    //         else setShowCheckIn(false);
    //     }).catch((err: any) => { toastMessage(err.message, 'error') })
    // }

    function onApiCall() {
        // checkInButtonApiCall();
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.attendance, params));
    }

    const columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return dayjs(rows[tableMeta.rowIndex]?.check_in).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') && (
                    <TableButtonGroup>
                        {rows[tableMeta.rowIndex]?.check_out ?
                            <TableEditButton
                                tooltipLabel={formatMessage({ id: "check-out" })}
                                onClick={() => handleRowClick(rows[tableMeta.rowIndex])}
                            /> :
                            <EditButton
                                label={formatMessage({ id: "check-out" })}
                                onClick={() => handleRowClick(rows[tableMeta.rowIndex])}
                            />
                        }
                        <DeleteButton
                            tooltipLabel="Delete"
                            onDelete={() => onDeleteData(rows[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    function handleRowClick(rowData: any) {
        setViewForm(true);
        setSelectedData(rowData)
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteAttendance({ id: data.id })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'attendance' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    const nameColumn = {
        label: formatMessage({ id: "name" }),
        name: "full_name",
    }

    function onSubmit() {
        services.createAttendance({ check_in: dayjs().format('hh:mm'), check_out: null })
            .then((res: any) => {
                toastMessage(formatMessage({ id: 'created-message' }, { title: formatMessage({ id: 'attendance' }) }));
                onApiCall();
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            })
    }

    function Toolbar() {
        return <TableButtonGroup>
            <Button
                color="primary"
                variant="contained"
                startIcon={<Fingerprint color="inherit" fontSize="inherit" />}
                onClick={onSubmit}
            >
                {formatMessage({ id: "check-in" })}
            </Button>
        </TableButtonGroup>
    }

    return (
        <PageLayout
            title={formatMessage({ id: "attendance" })}
            // customToolBar={isFullTime ? (showCheckIn ? <Toolbar /> : null) : null}
            // hideToolbar={isSupport && showCheckIn ? false : true}
            customToolBar={(isFullTime || isPartTime) ? <Toolbar /> : null}
            hideToolbar={isSupport ? false : true}
            onCreate={() => setViewForm(true)}
        >
            <CustomTable
                columns={isAdmin ? [nameColumn, ...attendanceColumns(formatMessage), columnAction] : [...attendanceColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />

            {viewForm &&
                <Form
                    refreshData={onApiCall}
                    selectedData={selectedData}
                    open={viewForm}
                    closeModal={() => setViewForm(false)}
                    headerText={'Attendance'}
                    formType={!selectedData ? 'create' : 'update'}
                />
            }

        </PageLayout>
    )
}

export default Employee;
