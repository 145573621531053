import { avatarOptions, ChipColumnOptions, dateOptions, dateTimeOptions, documentOptions, FlagColumnOptions, genderOptions, labelOption, timeOptionsFromDate } from ".";
import { leaveStatusOptions, statusOptions } from "..";

export const masterColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name-np" }),
  },
  {
    name: "is_active",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const roleColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "guard_name",
    label: formatMessage({ id: "guard-name" }),
  },
];

export const accountColumns = (formatMessage: any) => [
  {
    name: "logo_path",
    label: formatMessage({ id: "logo" }),
    ...avatarOptions
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "address",
    label: formatMessage({ id: "address" }),
    primaryColumnName: 'address_line1'
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },

  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
];

export const companyColumns = (formatMessage: any) => [
  {
    name: "logo_path",
    label: formatMessage({ id: "logo" }),
    ...avatarOptions
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    name: "address",
    label: formatMessage({ id: "address" }),
    primaryColumnName: 'address_line1'
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "is_flagged",
    label: formatMessage({ id: "is-flagged" }),
    ...FlagColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const employeeColumns = (formatMessage: any) => [
  {
    name: "profile_photo_url",
    label: formatMessage({ id: "photo" }),
    ...avatarOptions
  },
  {
    name: "document_url",
    label: formatMessage({ id: "cv" }),
    ...documentOptions
  },
  {
    name: "full_name_search",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "joining_date",
    label: formatMessage({ id: "joining-date" }),
    ...dateOptions
  },
  {
    name: "year_of_expericence",
    label: formatMessage({ id: "year-of-expericence" }),
  },
  {
    ...genderOptions,
    label: formatMessage({ id: "gender" }),
    name: "gender",
  },
];


export const agentColumns = (formatMessage: any) => [
  {
    name: "profile_photo_url",
    label: formatMessage({ id: "photo" }),
    ...avatarOptions
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "full_name_search",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    ...genderOptions,
    label: formatMessage({ id: "gender" }),
    name: "gender",
  },
];

export const companySearchColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "is_flagged",
    label: formatMessage({ id: "is-flagged" }),
    ...FlagColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const attendanceColumns = (formatMessage: any) => [
  {
    name: "check_in",
    label: formatMessage({ id: "date" }),
    ...dateOptions
  },
  {
    name: "check_in",
    label: formatMessage({ id: "check-in" }),
    ...timeOptionsFromDate
  },
  {
    name: "check_out",
    label: formatMessage({ id: "check-out" }),
    ...timeOptionsFromDate
  },
  {
    name: "remarks",
    label: formatMessage({ id: "remarks" }),
    options: {
      setCellProps: () => ({ style: { minWidth: "500px", maxWidth: "500px" } }),
    },
  },
];

export const attendanceExportColumns = (formatMessage: any) => [
  {
    name: "check_in",
    label: formatMessage({ id: "date" }),
    ...dateOptions
  },
  {
    name: "check_in",
    label: formatMessage({ id: "check-in" }),
    ...timeOptionsFromDate
  },
  {
    name: "check_out",
    label: formatMessage({ id: "check-out" }),
    ...timeOptionsFromDate
  },
  {
    name: "total_hour",
    label: formatMessage({ id: "hours" }),
    options: {
      setCellProps: () => ({ style: { minWidth: 50, maxWidth: 50 } }),
      customBodyRender: (value: string,tableMeta:any,asdasd:any) => {
        return <span>{value} hr</span>;
      },
    },
  },
];

export const leaveColumns = (formatMessage: any) => [
  {
    name: "start_date",
    label: formatMessage({ id: "start-date" }),
    ...dateOptions
  },
  {
    name: "end_date",
    label: formatMessage({ id: "end-date" }),
    ...dateOptions
  },
  {
    name: "is_approved",
    label: formatMessage({ id: "Status" }),
    ...ChipColumnOptions(leaveStatusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "remarks",
    label: formatMessage({ id: "remarks" }),
  },
];

export const leaveExportColumns = (formatMessage: any) => [
  {
    name: "start_date",
    label: formatMessage({ id: "start-date" }),
    ...dateOptions
  },
  {
    name: "end_date",
    label: formatMessage({ id: "end-date" }),
    ...dateOptions
  },
  {
    name: "is_approved",
    label: formatMessage({ id: "Status" }),
    ...ChipColumnOptions(leaveStatusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "total_days",
    label: formatMessage({ id: "days" }),
    options: {
      setCellProps: () => ({ style: { minWidth: 50, maxWidth: 50 } }),
      customBodyRender: (value: string) => {
        return <span>{value} day</span>;
      },
    },
  },
];



export const employeeClientColumns = (formatMessage: any) => [

  {
    name: "client",
    label: formatMessage({ id: "client" }),
    primaryColumnName: 'clients.full_name_search'
  },
  {
    name: "status",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    ...dateTimeOptions,
    label: formatMessage({ id: "assigned-at" }),
    name: "assigned_at",
  },
  {
    ...dateTimeOptions,
    label: formatMessage({ id: "terminated-at" }),
    name: "deleted_at",
  },
];

