import axios, { AxiosPromise } from "axios";
import { API_ENDPOINTS } from "redux/apiEndPoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any
}

export const appServices = {
  logoutUser: (params: ParamsState): AxiosPromise => axios.get(API_ENDPOINTS.LOGOUT, { params }),
  updatePassword: (params: ParamsState): AxiosPromise => axios.post(API_ENDPOINTS.UPDATE_PASSWORD, params),
  dashboard: (): AxiosPromise => axios.get(API_ENDPOINTS.DASHBOARD),

  createAccount: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_ACCOUNT, body),
  updateAccount: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_ACCOUNT, body),
  getAccountByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_ACCOUNT_BY_ID, body),
  getAllAccount: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ACCOUNT, body),
  deleteAccount: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_ACCOUNT, body),
  updateAccountByUuid: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_ACCOUNT+ '/' + body.uuid, body),

  createEmployee: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_EMPLOYEE, body),
  updateEmployee: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_EMPLOYEE, body),
  getAllEmployee: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_EMPLOYEE, body),
  getEmployeeByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_EMPLOYEE_BY_UUID, body),
  deleteEmployee: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_EMPLOYEE, params),
  getEmployeeLookup: () => new AxiosService().get(API_ENDPOINTS.EMPLOYEE_LOOKUP),
  updateEmployeeFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_EMPLOYEE_FLAG, body),
  searchEmployee: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.EMPLOYEE_SEARCH, body),
  dropdownEmployeeList: () => new AxiosService().get(API_ENDPOINTS.EMPLOYEE_DROPDOWN_LIST),

  createCompany: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_COMPANY, body),
  updateCompany: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_COMPANY, body),
  getAllCompany: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_COMPANY, body),
  getCompanyByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_COMPANY_BY_UUID, body),
  deleteCompany: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_COMPANY, params ),
  searchCompany: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.COMPANY_SEARCH, body),

  createAttendance: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_ATTENDANCE, body),
  updateAttendance: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_ATTENDANCE, body),
  getAllAttendance: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ATTENDANCE, body),
  getAttendanceByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_ATTENDANCE_BY_UUID, body),
  deleteAttendance: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_ATTENDANCE, params),
  checkInAttendance: () => new AxiosService().get(API_ENDPOINTS.CHECKIN_BUTTON_ATTENDANCE),

  createEmployeeClient: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_MENTOR_MENTEE, body),
  getSpecificEmployeeClient: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_MENTOR_MENTEE, body),
  getAllEmployeeClient: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_MENTOR_MENTEE, body),
  deleteEmployeeClient: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_MENTOR_MENTEE, params),
  getEmployeeClientLookup: () => new AxiosService().get(API_ENDPOINTS.MENTOR_MENTEE_LOOKUP),
  
  createAgent: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_AGENT, body),
  updateAgent: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_AGENT, body),
  getAllAgent: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_AGENT, body),
  getAgentByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_AGENT_BY_UUID, body),
  deleteAgent: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_AGENT, params),
  getAgentLookup: () => new AxiosService().get(API_ENDPOINTS.AGENT_LOOKUP),
  updateAgentFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_AGENT_FLAG, body),

  createLeave: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_LEAVE, body),
  updateLeave: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_LEAVE, body),
  getAllLeave: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_LEAVE, body),
  getLeaveByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_LEAVE_BY_UUID, body),
  deleteLeave: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_LEAVE, params),
  updateLeaveStatus: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_LEAVE_STATUS, body),


  getUserLookup: (): AxiosPromise => axios.post(API_ENDPOINTS.LOGOUT),

}