import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Grid } from '@mui/material';

import {  CustomRadioGroup, CustomTextArea } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { booleanTypes, booleanOptions } from 'utils/constants';

interface Props {
    open: boolean;
    closeModal: () => void;
    headerText: string;
    refreshData: () => void;
    selectedData: any
}

const FlagForm = (props: Props) => {
    const { closeModal, open, headerText, refreshData, selectedData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.id = selectedData.id;
        // data.is_flagged = data?.is_flagged ? 1 : 0;
        data.is_flagged =  1 ;
        const apiFunc = 'updateEmployeeFlag';
        const message = 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "employee" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err.message, 'error');
            })
    }

    useEffect(() => {
        if (selectedData) {
            reset({
                flagged_reason: selectedData?.flagged_reason,
                is_flagged: selectedData?.is_flagged ? true :false,
            });
        }
    }, [selectedData]);

    return (
        <CustomDrawer
            formMode={'update'}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "reason" })}
                        name="flagged_reason"
                        control={control}
                        minRows={6}
                        error={errors?.flagged_reason}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>

                {/* <Grid item xs={12}>
                    <CustomRadioGroup
                        label={formatMessage({ id: "is-flagged" }) + " " + formatMessage({ id: "employee" })}
                        name="is_flagged"
                        groupList={booleanOptions}
                        control={control}
                        labelPlacement="top"
                        defaultValue={booleanTypes.Yes}
                    />
                </Grid> */}
            </Grid>
        </CustomDrawer>
    )
}

export default FlagForm;